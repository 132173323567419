import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule, ButtonsModule, FeedbackModule, InputModule, LayoutModule, ModalsModule, StatusSpinnerModule } from '@skykick/core';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { EmailRecipientsFormComponent } from './core/email-recipients-form/email-recipients-form.component';
import { GeneralNotificationsAdminCenterComponent } from './general-notifications-admin-center/general-notifications-admin-center.component';
import { NotificationsAdminCenterComponent } from './notifications-admin-center.component';
import { ProductNotificationsAdminCenterComponent } from './product-notifications-admin-center/product-notifications-admin-center.component';

@NgModule({
  declarations: [
    NotificationsAdminCenterComponent,
    GeneralNotificationsAdminCenterComponent,
    ProductNotificationsAdminCenterComponent,
    EmailRecipientsFormComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    SharedModule,
    RouterModule,
    StatusSpinnerModule,
    FeedbackModule,
    ButtonsModule,
    ReactiveFormsModule,
    NgbModule,
    InputModule,
    ModalsModule,
    AccordionModule,
    ToastrModule.forRoot(),
  ],
})
export class NotificationsAdminCenterModule {}

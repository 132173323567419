import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  BannerModule,
  ButtonsModule,
  FeedbackModule,
  HeaderModule,
  LayoutModule,
  ModalsModule,
  NavigationModule,
  UtilityModule
} from '@skykick/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import * as moment from 'moment';
import { NgHttpCachingStrategy, provideNgHttpCaching } from 'ng-http-caching';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuiltWithLoveComponent } from './built-with-love/built-with-love.component';
import { CompleteMemberProfileFormModule } from './complete-member-profile-form/complete-member-profile-form.module';
import { DeveloperModule } from './developer/developer.module';
import { DnsManagerModule } from './dns-manager/dns-manager.module';
import { GageComponent } from './gage/gage.component';
import { HomeModule } from './home/home.module';
import { httpInterceptorProviders } from './interceptors';
import { LanguageLoader } from './language-loader';
import { LogoutComponent } from './logout/logout.component';
import { MpnRegistrationModule } from './mpn-registration/mpn-registration.module';
import { PortalModule } from './portal/portal.module';
import { ErrorHandlerService } from './services/error-handler.service';
import { UserProfileService } from './settings/user-profile/services/user-profile.service';
import { TermsAndConditionsModalComponent } from './terms-and-conditions/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { ConnectWiseProfileFormModule } from "./connectwise-signup/connectwise-profile-form.module";

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const providers: (Provider | EnvironmentProviders)[] = [
  TranslateStore,
  {
    provide: APP_INITIALIZER,
    useFactory: LanguageLoader,
    multi: true,
    deps: [
      TranslateService,
      CookieService,
      AbstractUserProvider,
      UserProfileService,
    ],
  },
  CookieService,
  { provide: ErrorHandler, useClass: ErrorHandlerService },
  provideHttpClient(withInterceptorsFromDi()),
  provideNgHttpCaching({
    lifetime: moment.duration(30, 'minutes').asMilliseconds(),
    allowedMethod: ['GET'],
    cacheStrategy: NgHttpCachingStrategy.ALLOW_ALL,
  }),
];

if (environment.production) {
  providers.push(httpInterceptorProviders);
}

@NgModule({ declarations: [
        AppComponent,
        BuiltWithLoveComponent,
        LogoutComponent,
        TermsAndConditionsModalComponent,
        GageComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        BannerModule,
        BrowserModule,
        BrowserAnimationsModule,
        NavigationModule,
        HeaderModule,
        LayoutModule,
        AppRoutingModule,
        NgbModule,
        PortalModule,
        ButtonsModule,
        ModalsModule,
        FeedbackModule,
        CompleteMemberProfileFormModule,
        ConnectWiseProfileFormModule,
        MpnRegistrationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationLoaderFactory,
                deps: [HttpClient],
            },
            isolate: true,
        }),
        UtilityModule.forRoot({ sewrSystemDomain: environment.sewrSystemDomain }),
        HomeModule,
        DeveloperModule,
        DnsManagerModule], providers: providers })
export class AppModule {}

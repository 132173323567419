export const EventSeverityToIconClass = new Map<string, string>([
    ['Info', 'icon-info'],
    ['Warning', 'icon-warning'],
    ['Critical', 'icon-danger'],
    ['Success', 'icon-success'],
]);

export const EventSeverityToIconName = new Map<string, string>([
    ['Info', 'info'],
    ['Warning', 'error'],
    ['Critical', 'warning'],
    ['Success', 'check_circle'],
]);

import { Component } from '@angular/core';
import { Products } from './core/models/ProductType';

@Component({
  selector: 'sk-notifications-admin-center',
  templateUrl: './notifications-admin-center.component.html',
  styleUrl: './notifications-admin-center.component.scss'
})
export class NotificationsAdminCenterComponent {
  products = Products;
}

import { NotificationAdminCenterEventConfigurationSettingsRequest } from "../models/NotificationAdminCenterEventConfigurationSettingsRequest";
import { ODataNotificationAdminCenterEvent } from "../models/ODataNotificationAdminCenterEvent";

export const mapToEventPreferencesRequest = (groupedEvents: [string, ODataNotificationAdminCenterEvent[]][])
  : NotificationAdminCenterEventConfigurationSettingsRequest[] =>
    {
      return groupedEvents
        .flatMap(([_, value]) => value)
        .flatMap(v => v.NotificationAdminCenterEventConfigurations)
        .map(c => {
          return {
            eventConfigurationKey: c.EventConfigurationKey,
            checked: c.Checked
          }
        });
    }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CanDeactivatePage,
  SkyKickModal,
  SkyKickModalService,
  SkyKickModalWarningOptions,
  WarningModalComponent,
} from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { Contact } from '../core/models/Contact';
import { ProductLevelPreferences } from '../core/models/ProductLevelPreferences';
import { Products } from '../core/models/ProductType';
import { NotificationsAdminCenterResourceService } from '../core/services/notifications-admin-center-resource.service';
import { formatContacts } from '../core/utils/format-contacts.util';
import { getDefaultODataEventSearchString } from '../core/utils/event-search-filters.util';
import { groupBy, cloneDeep } from 'lodash-es';
import { ODataNotificationAdminCenterEvent } from '../core/models/ODataNotificationAdminCenterEvent';
import { DeliveryMediums } from '../core/utils/delivery-medium.util';
import { EventSeverityToIconClass, EventSeverityToIconName } from '../core/utils/event-severity.util';
import { NotificationAdminCenterEventSettingsRequest } from '../core/models/NotificationAdminCenterEventSettingsRequest';
import { mapToEventPreferencesRequest } from '../core/utils/event-preferences.util';

@Component({
  selector: 'sk-product-notifications-admin-center',
  templateUrl: './product-notifications-admin-center.component.html',
  styleUrl: './product-notifications-admin-center.component.scss',
})
export class ProductNotificationsAdminCenterComponent
  extends CanDeactivatePage
  implements OnInit, OnDestroy
{
  constructor(
    private readonly notificationsAdminCenterResourceService: NotificationsAdminCenterResourceService,
    private readonly toastrService: ToastrService,
    private readonly userService: AbstractUserProvider,
    private readonly route: ActivatedRoute,
    translateService: TranslateService,
    modalService: SkyKickModalService
  ) {
    super(modalService, translateService);
  }

  eventSeverityToIconClass = EventSeverityToIconClass;
  eventSeverityToIconName = EventSeverityToIconName;
  loadingProductPreferences = false;
  productPreferencesFailedToLoad = false;
  initialProductPreferences: Contact[];
  newProductPreferences: Contact[];
  initialGroupedEvents: [string, ODataNotificationAdminCenterEvent[]][];
  newGroupedEvents: [string, ODataNotificationAdminCenterEvent[]][];
  productId: number;
  productName: string;

  savingPreferences = false;
  preferencesCancelled = new Subject<void>();
  searchString: string;

  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.productName = params.get('product');

      const product = Products.find(p => p.name === this.productName);
      this.productId = product.id;
      this.loadingProductPreferences = true;
      this.searchString = getDefaultODataEventSearchString(product.notificationsName);

      forkJoin([
        this.notificationsAdminCenterResourceService.getProductLevelPreferences(this.productId),
        this.notificationsAdminCenterResourceService.getODataEvents(this.searchString, this.productId)
      ]).pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: ([productLevelPreferences, eventsResponse]) => {
            this.loadingProductPreferences = false;
            const formattedContacts = formatContacts(productLevelPreferences);
            this.initialProductPreferences = cloneDeep(formattedContacts);
            this.newProductPreferences = cloneDeep(formattedContacts);
            const groupedEvents = Object.entries(groupBy(eventsResponse.value, e => `${e.CategoryKey}_${e.CategoryName}`));
            this.initialGroupedEvents = cloneDeep(groupedEvents);
            this.newGroupedEvents = cloneDeep(groupedEvents);
          },
          error: () => {
            this.loadingProductPreferences = false;
            this.productPreferencesFailedToLoad = true;
          },
        });
    });
  }

  updateProductPreferences(preferences: Contact[]) {
    this.newProductPreferences = cloneDeep(preferences);
  }

  updateEventPreferences(groupedEvents: [string, ODataNotificationAdminCenterEvent[]][]) {
    this.newGroupedEvents = cloneDeep(groupedEvents);
  }

  save() {
    this.savingPreferences = true;
    const user = this.userService.getCurrentUser();

    const productPreferences: ProductLevelPreferences = {
      productId: this.productId,
      createdBy: user.fullName,
      criticalContacts: this.newProductPreferences,
      informationContacts: this.newProductPreferences,
      successContacts: this.newProductPreferences,
      warningContacts: this.newProductPreferences,
    };

    const eventPreferences: NotificationAdminCenterEventSettingsRequest = {
      partnerId: user.partnerId,
      createdBy: user.fullName,
      notificationAdminCenterEventConfigurationSettings: mapToEventPreferencesRequest(this.newGroupedEvents)
    }

    forkJoin([
      this.notificationsAdminCenterResourceService
        .syncProductLevelPreferences(productPreferences),
      this.notificationsAdminCenterResourceService
        .syncEventPreferences(eventPreferences, this.productId)
    ]).pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: () => {
          this.initialProductPreferences = cloneDeep(this.newProductPreferences);
          this.initialGroupedEvents = cloneDeep(this.newGroupedEvents);
          this.toastrService.success(
            this.translateService.instant(
              `sk-notifications-admin-center.preferences.${this.productName}.save_success_message`
            )
          );
        },
        error: () => {
          this.toastrService.error(
            this.translateService.instant(
              `sk-notifications-admin-center.preferences.${this.productName}.save_error_message`
            )
          );
        },
      })
      .add(() => {
        this.savingPreferences = false;
      });
  }

  cancel() {
    if (this.preferencesChanged()) {
      let warningModal: SkyKickModal<WarningModalComponent, void>;
      const options: SkyKickModalWarningOptions = {
        body: this.translateService.instant(
          `sk-notifications-admin-center.preferences.${this.productName}.cancel_modal_body`
        ),
        title: this.translateService.instant(
          `sk-notifications-admin-center.preferences.${this.productName}.cancel_modal_title`
        ),
        btnLabel: this.translateService.instant(
          `sk-notifications-admin-center.preferences.general.cancel_modal_ok_button_text`
        ),
        alternative: {
          btnLabel: this.translateService.instant(
            `sk-notifications-admin-center.preferences.general.cancel_modal_cancel_button_text`
          ),
          btnCallback: () => {
            warningModal.dismiss();
          },
        },
      };
      warningModal = this.modalService.warning(options);
      warningModal.result.then((res) => {
        if (res.wasClosed) {
          this.updateProductPreferences(this.initialProductPreferences);
          this.updateEventPreferences(this.initialGroupedEvents);
          this.preferencesCancelled.next();
        }
      });
    }
  }

  getMediumDisplayName(deliveryMedium: string) {
    return DeliveryMediums.find(d => d.name === deliveryMedium).displayName;
  }

  getConfigurations(groupKey: string, deliveryMedium: string) {
    return this.newGroupedEvents
      .find(([key, _]) => key === groupKey)[1]
      .flatMap(e => e.NotificationAdminCenterEventConfigurations
      .filter(c => c.DistributionMedium === deliveryMedium));
  }

  getGeneralDisabledValueForMedium(groupKey: string, deliveryMedium: string) {
    return this.getConfigurations(groupKey, deliveryMedium).every(c => !c.Enabled);
  }

  getGeneralCheckedValueForMedium(groupKey: string, deliveryMedium: string) {
    const configurations = this.getConfigurations(groupKey, deliveryMedium);

    // if all configurations are disabled and not checked return false
    // else if all configurations are checked or disabled return true
    // else return false
    return !configurations.every(c => !c.Enabled && !c.Checked) &&
      configurations.every(c => c.Checked || !c.Enabled);
  }

  checkGeneralCheckbox(groupKey: string, deliveryMedium: string, value: boolean) {
    this.newGroupedEvents
      .find(([key, _]) => key === groupKey)[1]
      .flatMap(e => e.NotificationAdminCenterEventConfigurations
      .filter(c => c.DistributionMedium === deliveryMedium))
      .forEach(v => v.Checked = v.Enabled ? value : v.Checked);
  }

  checkSingleCheckbox(groupKey: string, eventConfigurationKey: string, value: boolean) {
    const configuration = this.newGroupedEvents
      .find(([key, _]) => key === groupKey)[1]
      .flatMap(e => e.NotificationAdminCenterEventConfigurations)
      .find(c => c.EventConfigurationKey === eventConfigurationKey);

    configuration.Checked = configuration.Enabled ? value : configuration.Checked;
  }

  preferencesChanged() {
    this.showCanDeactivateModal = !isEqual(this.initialProductPreferences, this.newProductPreferences)
      || !isEqual(this.initialGroupedEvents, this.newGroupedEvents);
    return this.showCanDeactivateModal;
  }

  isButtonDisabled() {
    return (
      this.loadingProductPreferences ||
      this.productPreferencesFailedToLoad ||
      this.savingPreferences ||
      !this.preferencesChanged()
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.preferencesCancelled.complete();
  }
}

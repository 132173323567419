import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SkyKickModalService, SkyKickPanelTabViewBase } from '@skykick/core';

import { RelativeUrlsProvider } from 'src/app/settings/shared/services/relative.urls.provider';
import { AuthenticationType } from 'src/app/settings/users/models/partner-portal-user-claims';

import { MemberStatus } from '../models/member-status';
import { ResetMfaModalComponent } from '../reset-mfa-modal/reset-mfa-modal.component';
import { MemberValidator } from '../services/member-validator';
import { MembersRoleProvider } from '../services/members.role.provider';
import { MembersStatusProvider } from '../services/members.status.provider';
import { MemberDetailsPanelContext } from './models/member-details-panel-context';
import MemberDetailsViewModel from './models/member-details-view-model';

@Component({
    /* tslint:disable-next-line:component-selector */
    selector: '[sk-member-details-panel]',
    templateUrl: './member-details-panel.component.html',
    styleUrls: ['./member-details-panel.component.scss']
})
export class MemberDetailsPanelComponent extends SkyKickPanelTabViewBase<MemberDetailsPanelContext, void> implements OnInit {

    member: MemberDetailsViewModel;
    areAllUsersMatched: boolean;

    constructor(
        private router: Router,
        private modalService: SkyKickModalService,
        private memberValidator: MemberValidator) {
        super();
    }

    ngOnInit(): void {
        this.member = this.data.viewModel;
        this.areAllUsersMatched = this.data.areAllUsersMatched;
    }

    getDisplayName(): string {
        return this.member.status === MembersStatusProvider.getStatusValueByKey(MemberStatus.Pending) ?
            this.member.email : this.member.fullName;
    }

    getAuthTypeLocKey(): string {
        let authTypeLocKey = 'settings.common.authentication.skykick';
        if (this.isM365Authentication()) {
            authTypeLocKey = 'settings.common.authentication.m365';
        } else if (this.member.partnerClaims.isMFAEnabled) {
            authTypeLocKey = 'settings.common.authentication.skykick-mfa';
        }
        return authTypeLocKey;
    }

    isM365Authentication(): boolean{
        return this.member.partnerClaims.authenticationType === AuthenticationType.M365Auth;
    }

    getAccountSettingsPageUrl(): string {
        return RelativeUrlsProvider.ManageAccountPageUrl;
    }

    getPermissionLocKey(memberRoleKey: string): string {
        return MembersRoleProvider.getRoleDisplayNameLocKeyByKey(memberRoleKey, true);
    }

    getValidationErrors(): string[] {
        return this.memberValidator.getMemberErrors(this.member, this.member.partnerClaims)
    }

    isCurrentUserAdmin(): boolean {
        return this.member.partnerClaims && MembersRoleProvider.isAdmin(this.member.partnerClaims.legacyAccessRole);
    }

    showFooter(): boolean {
        return this.member.status === MembersStatusProvider.getStatusValueByKey(MemberStatus.Active);
    }

    editPermissions(): void {
        this.router.navigate(['settings', 'users', 'members', this.member.id, 'edit']);
    }

    isResetMfaAvailable(): boolean {
        return this.member.isMfaEnabled && this.member.status === MembersStatusProvider.getStatusValueByKey(MemberStatus.Active);
    }

    resetMfa(): void {
        const modalRef = this.modalService.open<ResetMfaModalComponent, any>(
            ResetMfaModalComponent,
            { backdrop: 'static' }
        );
        modalRef.componentInstance.memberEmail = this.member.email;
        modalRef.result;
    }
}

export const DeliveryMediums = [
    {
        name: 'InPortal',
        displayName: 'sk-notifications-admin-center.preferences.general.events_table.headers.mediums.inPortal'
    },
    {
        name: 'Email',
        displayName: 'sk-notifications-admin-center.preferences.general.events_table.headers.mediums.email'
    },
];

<form
  class="mb-100"
  [formGroup]="emailRecipientsForm"
  [@fadeIn]
>
  <div class="d-flex justify-content-between">
    <label class="contacts-label font-medium" for="contacts">
      {{ label }}
    </label>
    <span class="text-muted" *ngIf="optional">
      {{ 'sk-notifications-admin-center.preferences.general.optional_label' | translate }}
    </span>
  </div>
  <sk-tags-input-email
    class="sk-tags-input"
    initialMode="write"
    id="contacts"
    formControlName="contacts"
    [placeholder]="'sk-notifications-admin-center.preferences.general.placeholder' | translate"
    validationPattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
    [validationPatternDescription]="'sk-notifications-admin-center.preferences.general.validation_message' | translate"
    [allowPastingMultipleEmails]="allowMultipleRecipients"
    [allowMultipleTags]="allowMultipleRecipients"
    [disabled]="savingPreferences"
  ></sk-tags-input-email>
  <small class="text-muted">{{ helpText }}</small>
</form>

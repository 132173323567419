import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CanDeactivatePage, SkyKickModal, SkyKickModalService, SkyKickModalWarningOptions, WarningModalComponent } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { isEqual, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { Contact } from '../core/models/Contact';
import { ProductLevelPreferences } from '../core/models/ProductLevelPreferences';
import { ProductType } from '../core/models/ProductType';
import { NotificationsAdminCenterResourceService } from '../core/services/notifications-admin-center-resource.service';
import { formatContacts } from '../core/utils/format-contacts.util';

@Component({
  selector: 'sk-general-notifications-admin-center',
  templateUrl: './general-notifications-admin-center.component.html',
  styleUrl: './general-notifications-admin-center.component.scss',
})
export class GeneralNotificationsAdminCenterComponent
  extends CanDeactivatePage
  implements OnInit, OnDestroy
{
  constructor(
    private readonly notificationsAdminCenterResourceService: NotificationsAdminCenterResourceService,
    private readonly toastrService: ToastrService,
    private readonly userService: AbstractUserProvider,
    translateService: TranslateService,
    modalService: SkyKickModalService
  ) {
    super(modalService, translateService);
  }

  loadingAccountingPreferences = false;
  accountPreferencesFailedToLoad = false;
  initialAccountPreferences: Contact[];
  newAccountPreferences: Contact[];

  loadingBillingPreferences = false;
  billingPreferencesFailedToLoad = false;
  initialBillingPreferences: Contact[];
  newBillingPreferences: Contact[];

  savingPreferences = false;
  preferencesCancelled = new Subject<void>();

  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.loadingAccountingPreferences = true;
    this.loadingBillingPreferences = true;

    this.notificationsAdminCenterResourceService
      .getProductLevelPreferences(ProductType.Account)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (productLevelPreferences: ProductLevelPreferences) => {
          this.loadingAccountingPreferences = false;
          const formattedContacts = formatContacts(productLevelPreferences);
          this.initialAccountPreferences = cloneDeep(formattedContacts);
          this.newAccountPreferences = cloneDeep(formattedContacts);
        },
        error: () => {
          this.loadingAccountingPreferences = false;
          this.accountPreferencesFailedToLoad = true;
        },
      });

    this.notificationsAdminCenterResourceService
      .getProductLevelPreferences(ProductType.Billing)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (productLevelPreferences: ProductLevelPreferences) => {
          this.loadingBillingPreferences = false;
          const formattedContacts = formatContacts(productLevelPreferences);
          this.initialBillingPreferences = cloneDeep(formattedContacts);
          this.newBillingPreferences = cloneDeep(formattedContacts);
        },
        error: () => {
          this.loadingBillingPreferences = false;
          this.billingPreferencesFailedToLoad = true;
        },
      });
  }

  updateAccountPreferences(preferences: Contact[]) {
    this.newAccountPreferences = cloneDeep(preferences);
  }

  updateBillingPreferences(preferences: Contact[]) {
    this.newBillingPreferences = cloneDeep(preferences);
  }

  save() {
    this.savingPreferences = true;
    const createdBy = this.userService.getCurrentUser().fullName;

    const accountPreferences: ProductLevelPreferences = {
      productId: ProductType.Account,
      createdBy: createdBy,
      criticalContacts: this.newAccountPreferences,
      informationContacts: this.newAccountPreferences,
      successContacts: this.newAccountPreferences,
      warningContacts: this.newAccountPreferences,
    };

    const billingPreferences: ProductLevelPreferences = {
      productId: ProductType.Billing,
      createdBy: createdBy,
      criticalContacts: this.newBillingPreferences,
      informationContacts: this.newBillingPreferences,
      successContacts: this.newBillingPreferences,
      warningContacts: this.newBillingPreferences,
    };

    forkJoin([
      this.notificationsAdminCenterResourceService.syncProductLevelPreferences(
        accountPreferences
      ),
      this.notificationsAdminCenterResourceService.syncProductLevelPreferences(
        billingPreferences
      ),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: () => {
          this.initialAccountPreferences = cloneDeep(this.newAccountPreferences);
          this.initialBillingPreferences = cloneDeep(this.newBillingPreferences);
          this.toastrService.success(
            this.translateService.instant(
              'sk-notifications-admin-center.preferences.general.save_success_message'
            )
          );
        },
        error: () => {
          this.toastrService.error(
            this.translateService.instant(
              'sk-notifications-admin-center.preferences.general.save_error_message'
            )
          );
        },
      })
      .add(() => {
        this.savingPreferences = false;
      });
  }

  cancel() {
    if (this.preferencesChanged()) {
      let warningModal: SkyKickModal<WarningModalComponent, void>;
      const options: SkyKickModalWarningOptions = {
        body: this.translateService.instant(
          'sk-notifications-admin-center.preferences.general.cancel_modal_body'
        ),
        title: this.translateService.instant(
          'sk-notifications-admin-center.preferences.general.cancel_modal_title'
        ),
        btnLabel: this.translateService.instant(
          'sk-notifications-admin-center.preferences.general.cancel_modal_ok_button_text'
        ),
        alternative: {
          btnLabel: this.translateService.instant(
            'sk-notifications-admin-center.preferences.general.cancel_modal_cancel_button_text'
          ),
          btnCallback: () => {
            warningModal.dismiss();
          },
        },
      };
      warningModal = this.modalService.warning(options);
      warningModal.result.then((res) => {
        if (res.wasClosed) {
          this.updateAccountPreferences(this.initialAccountPreferences);
          this.updateBillingPreferences(this.initialBillingPreferences);
          this.preferencesCancelled.next();
        }
      });
    }
  }

  preferencesChanged() {
    this.showCanDeactivateModal =
      !isEqual(this.initialAccountPreferences, this.newAccountPreferences) ||
      !isEqual(this.initialBillingPreferences, this.newBillingPreferences);

    return this.showCanDeactivateModal;
  }

  isButtonDisabled() {
    return (
      this.loadingAccountingPreferences ||
      this.loadingBillingPreferences ||
      this.accountPreferencesFailedToLoad ||
      this.billingPreferencesFailedToLoad ||
      this.savingPreferences ||
      !this.preferencesChanged()
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.preferencesCancelled.complete();
  }
}

export enum ProductType {
  Migrations = 1,
  Backup = 2,
  CloudSecurity = 3,
  Account = 4,
  Billing = 5
}

export const Products = [
  {
    name: 'cloudbackup',
    notificationsName: 'Backup',
    displayName: 'sk-notifications-admin-center.tabs.cloud_backup',
    id: ProductType.Backup
  },
];

import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { CanDeactivateGuard } from '@skykick/core';
import { GeneralNotificationsAdminCenterComponent } from './general-notifications-admin-center/general-notifications-admin-center.component';
import { NotificationsAdminCenterComponent } from './notifications-admin-center.component';
import { ProductNotificationsAdminCenterComponent } from './product-notifications-admin-center/product-notifications-admin-center.component';

export const NOTIFICATIONS_ADMIN_CENTER_ROUTES: Routes = [
  {
    path: 'notifications',
    redirectTo: 'notifications/general',
    pathMatch: 'prefix',
  },
  {
    path: 'notifications',
    component: NotificationsAdminCenterComponent,
    children: [
      {
        path: 'general',
        canDeactivate: [CanDeactivateGuard],
        component: GeneralNotificationsAdminCenterComponent,
      },
      {
        path: 'products/:product',
        canDeactivate: [CanDeactivateGuard],
        component: ProductNotificationsAdminCenterComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(NOTIFICATIONS_ADMIN_CENTER_ROUTES)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}

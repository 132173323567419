<div class="sk-flex-table">
  <div class="sk-scroll-container pt-200">
    <div class="pt-100" *ngIf="loadingProductPreferences">
      <sk-text-spinner
        [message]="'sk-notifications-admin-center.preferences.' + productName + '.loader_text' | translate"
      ></sk-text-spinner>
    </div>
    <div *ngIf="!loadingProductPreferences">
      <h3 class="pb-100">{{ 'sk-notifications-admin-center.title.' + productName + '.text' | translate }}</h3>
      <span>{{ 'sk-notifications-admin-center.title.' + productName + '.note' | translate }}</span>
      <div class="pt-100 col-md-5">
        <div class="pb-50">
          <sk-email-recipients-form
            *ngIf="!loadingProductPreferences && !productPreferencesFailedToLoad"
            [helpText]="'sk-notifications-admin-center.preferences.' + productName + '.help_text' | translate"
            [label]="'sk-notifications-admin-center.preferences.' + productName + '.label' | translate"
            [emailRecipients]="newProductPreferences"
            [savingPreferences]="savingPreferences"
            [isPrimary]="true"
            [optional]="true"
            [allowMultipleRecipients]="false"
            [preferencesCancelled]="preferencesCancelled.asObservable()"
            (formValue)="updateProductPreferences($event)">
          </sk-email-recipients-form>
          <sk-feedback
            *ngIf="productPreferencesFailedToLoad"
            [dismissible]="false"
            [subject]="'sk-notifications-admin-center.preferences.' + productName + '.fetch_error_title' | translate"
            [message]="'sk-notifications-admin-center.preferences.' + productName + '.fetch_error_message' | translate"
            type="danger"
          >
          </sk-feedback>
        </div>
      </div>
      <div>
        <hr />
        <h3 class="py-100">{{ 'sk-notifications-admin-center.secondary_title.text' | translate }}</h3>
        <span class="text-muted">{{ 'sk-notifications-admin-center.secondary_title.subText' | translate }}</span>
        <div class="pt-100">
          <div class="pb-50">
            <div class="admin-center-accordion">
              <sk-accordion expandable="true">
                <ng-container *ngFor="let eventGroup of newGroupedEvents">
                  <sk-accordion-item [title]="eventGroup[0].split('_')[1]" expanded="true">
                      <ng-template sk-accordion-body>
                        <table class="table table-hover sk-standard-wrap-table">
                          <thead class="thead-white thead-sm thead-border-none">
                            <tr>
                              <th class="w-75 primary-table-header">{{ 'sk-notifications-admin-center.preferences.general.events_table.headers.notification_subject' | translate }}</th>
                              <th class="delivery-medium-column">{{ 'sk-notifications-admin-center.preferences.general.events_table.headers.severity' | translate }}</th>
                              <ng-container *ngFor="let medium of eventGroup[1][0].NotificationAdminCenterEventConfigurations">
                                <th class="delivery-medium-column">
                                  <div class="d-flex align-items-center">
                                    <input [checked]="getGeneralCheckedValueForMedium(eventGroup[0], medium.DistributionMedium)"
                                      [disabled]="getGeneralDisabledValueForMedium(eventGroup[0], medium.DistributionMedium)"
                                      (change)="checkGeneralCheckbox(eventGroup[0], medium.DistributionMedium, !getGeneralCheckedValueForMedium(eventGroup[0], medium.DistributionMedium))"
                                      type="checkbox" class="form-check-input">
                                    <span class="ps-2">
                                      {{ getMediumDisplayName(medium.DistributionMedium) | translate }}
                                    </span>
                                  </div>
                                </th>
                              </ng-container>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let eventData of eventGroup[1]">
                              <td class="min-w-0 px-100">
                                <span class="font-medium text-truncate" [ngbTooltip]="eventData.Name" [openDelay]="400">
                                  {{ eventData.Name }}
                                </span>
                              </td>
                              <td>
                                <span [ngbTooltip]="eventData.EventSeverity" [openDelay]="400" class="d-flex ps-2">
                                  <i class="material-icons md-22 {{ eventSeverityToIconClass.get(eventData.EventSeverity) }}">{{
                                    eventSeverityToIconName.get(eventData.EventSeverity)
                                  }}</i>
                                </span>
                              </td>
                              <ng-container *ngFor="let medium of eventData.NotificationAdminCenterEventConfigurations">
                                <td>
                                  <div class="d-flex align-items-center">
                                    <input [checked]="medium.Checked" [disabled]="!medium.Enabled"
                                      (change)="checkSingleCheckbox(eventGroup[0], medium.EventConfigurationKey, !medium.Checked)"
                                      type="checkbox" class="form-check-input">
                                  </div>
                                </td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </ng-template>
                  </sk-accordion-item>
                </ng-container>
              </sk-accordion>
            </div>
            <div class="col-md-5">
              <sk-feedback
                *ngIf="productPreferencesFailedToLoad"
                [dismissible]="false"
                [subject]="'sk-notifications-admin-center.preferences.' + productName + '.fetch_error_title' | translate"
                [message]="'sk-notifications-admin-center.preferences.' + productName + '.fetch_error_message' | translate"
                type="danger"
              >
              </sk-feedback>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sk-page-footer">
    <div class="sk-footer-content">
      <button sk-spinner-button class="btn-lg mr-100" [color]="'primary'" (click)="save()"
        [disabled]="isButtonDisabled()"
        [isSpinning]="savingPreferences">
        {{ 'sk-notifications-admin-center.save_button_text' | translate }}
      </button>
       <button (click)="cancel()" [disabled]="isButtonDisabled()" type="button" class="btn btn-lg btn-outline-secondary">
        {{ 'sk-notifications-admin-center.cancel_button_text' | translate }}
      </button>
    </div>
  </div>
</div>

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contact } from '../models/Contact';
import { ContactNames } from '../models/ContactName';
import { ContactType } from '../models/ContactType';

@Component({
  selector: 'sk-email-recipients-form',
  templateUrl: './email-recipients-form.component.html',
  styleUrl: './email-recipients-form.component.scss',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class EmailRecipientsFormComponent implements OnInit {
  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  @Input() optional: boolean;
  @Input() preferencesCancelled: Observable<void>;
  @Input() isPrimary: boolean;
  @Input() savingPreferences: boolean;
  @Input() emailRecipients: Contact[];
  @Input() label: string;
  @Input() helpText: string;
  @Input() allowMultipleRecipients: boolean;
  @Output() formValue = new EventEmitter<Contact[]>();

  initialEmailRecipients: Contact[];

  emailRecipientsForm: UntypedFormGroup;

  ngOnInit(): void {
    this.initialEmailRecipients = this.emailRecipients;

    this.emailRecipientsForm = this.formBuilder.group({
      contacts: new UntypedFormControl(this.getContactValues()),
    });

    this.emailRecipientsForm.valueChanges.subscribe((value) => {
      const contacts = value.contacts.map((c: string) => {
        return {
          name: this.isPrimary ? ContactNames.primary : ContactNames.additional,
          value: c,
          contactType: ContactType.Email,
        };
      });

      this.formValue.emit(contacts);
    });

    this.preferencesCancelled.subscribe(() => {
      this.emailRecipientsForm
        .get('contacts')
        .setValue(this.getContactValues());
    });
  }

  getContactValues() {
    return this.initialEmailRecipients.map((p) => p.value);
  }
}

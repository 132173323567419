<div class="sk-flex-table">
  <div class="sk-page-header pb-100">
      <div class="sk-navbar-primary single-navbar">
          <div class="d-flex align-items-center min-w-0">
              <div class="d-flex min-w-0">
                  <div class="min-w-0">
                      <h3>{{ 'sk-notifications-admin-center.notifications' | translate }}</h3>
                  </div>
              </div>
          </div>
      </div>
    <ul sk-page-header-nav class="sk-tabs-lg d-flex">
      <li
        sk-page-header-nav-item
        link="general"
        [label]="'sk-notifications-admin-center.tabs.general' | translate"
      >
        {{ 'sk-notifications-admin-center.tabs.general' | translate }}
      </li>
      <li
        *ngFor="let product of products"
        sk-page-header-nav-item
        link="products/{{ product.name }}"
        [label]="'sk-notifications-admin-center.tabs.' + product.name | translate"
      >
        {{ 'sk-notifications-admin-center.tabs.' + product.name | translate }}
      </li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>

import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { getCustomFlattenedPermissionName, getFlattenedPermissionName, NavigationArea, NavigationGuard, NavigationGuardData, NavigationPermission } from '@skykick/core';
import { CustomPermissions } from 'src/app/utilities/custom-permissions.util';
import {
  AccountNotificationSettingsComponent,
} from './components/account-notification-settings/account-notification-settings.component';
import {
  BackupNotificationSettingsComponent,
} from './components/backup-notification-settings/backup-notification-settings.component';
import {
  MigrationNotificationSettingsComponent,
} from './components/migration-notification-settings/migration-notification-settings.component';
import {
  NotificationSettingsComponent,
} from './components/notification-settings.component';

export const NOTIFICATIONS_ROUTES_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.notifications,
    NavigationPermission.Read
  ),
  getFlattenedPermissionName(
    NavigationArea.Migrations,
    NavigationPermission.FullAccess
  ),
  getFlattenedPermissionName(
    NavigationArea.CloudBackup,
    NavigationPermission.FullAccess
  )
];

export const NOTIFICATIONS_ROUTES: Routes = [
  {
    path: 'notifications',
    redirectTo: 'notifications/account',
    pathMatch: 'prefix',
  },
  {
    path: 'notifications',
    component: NotificationSettingsComponent,
    canActivate: [NavigationGuard],
    data: {
      navigationGuardData: {
        optionalPermissions: NOTIFICATIONS_ROUTES_PERMISSIONS,
        permissionAbsentRedirect: 'settings',
      } as NavigationGuardData,
    },
    children: [
      {
        path: 'account',
        component: AccountNotificationSettingsComponent,
        canActivate: [NavigationGuard],
        data: {
          name: 'Account',
          type: 'secondary',
          navigationGuardData: {
            mandatoryPermissions: [NOTIFICATIONS_ROUTES_PERMISSIONS[0]],
            permissionAbsentRedirect: 'settings/notifications/migrations',
          } as NavigationGuardData,
        },
      },
      {
        path: 'migrations',
        component: MigrationNotificationSettingsComponent,
        canActivate: [NavigationGuard],
        data: {
          name: 'Migrations',
          type: 'secondary',
          navigationGuardData: {
            mandatoryPermissions: [NOTIFICATIONS_ROUTES_PERMISSIONS[1]],
            permissionAbsentRedirect: 'settings/notifications/backup',
          } as NavigationGuardData,
        },
      },
      {
        path: 'backup',
        component: BackupNotificationSettingsComponent,
        canActivate: [NavigationGuard],
        data: {
          name: 'Backup',
          type: 'secondary',
          navigationGuardData: {
            mandatoryPermissions: [NOTIFICATIONS_ROUTES_PERMISSIONS[2]],
            permissionAbsentRedirect: 'settings/notifications/account'
          } as NavigationGuardData,
        },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(NOTIFICATIONS_ROUTES)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}

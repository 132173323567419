import { intersectionBy } from "lodash-es";
import { ProductLevelPreferences } from "../models/ProductLevelPreferences";

export const formatContacts = (productLevelPreferences: ProductLevelPreferences) => {
    return intersectionBy(
      productLevelPreferences.criticalContacts,
      productLevelPreferences.informationContacts,
      productLevelPreferences.successContacts,
      productLevelPreferences.warningContacts,
      (p) => p.value
    ).map(val => {
      return {
        name: val.name,
        value: val.value,
        contactType: val.contactType
      };
    });
}

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppId, RouteResolverService } from "@skykick/core";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { NgHttpCachingHeaders, NgHttpCachingService } from "ng-http-caching";
import { catchError, Observable, throwError } from 'rxjs';
import { ProductLevelPreferences } from "../models/ProductLevelPreferences";
import { ODataNotificationAdminCenterEventResponse } from "../models/ODataNotificationAdminCenterEventResponse";
import { CookieService } from "ngx-cookie-service";
import { NotificationAdminCenterEventSettingsRequest } from "../models/NotificationAdminCenterEventSettingsRequest";

@Injectable({
  providedIn: 'root',
})
export class NotificationsAdminCenterResourceService {
  constructor(
    private readonly http: HttpClient,
    private readonly userProvider: AbstractUserProvider,
    private readonly routeResolverService: RouteResolverService,
    private readonly ngHttpCachingService: NgHttpCachingService,
    private readonly cookieService: CookieService
  ) {}

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse);
    }
    console.log(
      'There is a problem with the service. We are notified & working on it. Please try again later.'
    );
    return throwError(() => errorResponse);
  }

  private getNotificationsApiRoute(): string {
    return (
      this.routeResolverService.generatePipeRoute(AppId.PlatformApis) +
      'notifications'
    );
  }

  private getNotificationsODataRoute(): string {
    return (
      this.routeResolverService.generatePipeRoute(AppId.PlatformApis) +
      'notifications-odata'
    );
  }

  getProductLevelPreferences(
    productId: number
  ): Observable<ProductLevelPreferences> {
    return this.http
      .get<ProductLevelPreferences>(
        `${this.getNotificationsApiRoute()}/api/v1/notifications/partners/${
          this.userProvider.getCurrentUser().partnerId
        }/preferences/products/${productId}`,
        {
          headers: {
            [NgHttpCachingHeaders.TAG]: `productLevelPreferencesGet-${productId}`,
          },
        }
      )
      .pipe(catchError(this.handleError));
  }

  syncProductLevelPreferences(
    payload: ProductLevelPreferences
  ): Observable<ProductLevelPreferences> {
    this.ngHttpCachingService.clearCacheByTag(
      `productLevelPreferencesGet-${payload.productId}`
    );
    return this.http
      .post<ProductLevelPreferences>(
        `${this.getNotificationsApiRoute()}/api/v1/notifications/partners/${
          this.userProvider.getCurrentUser().partnerId
        }/preferences/products`,
        payload
      )
      .pipe(catchError(this.handleError));
  }

  getODataEvents(searchString: string, productId: number): Observable<ODataNotificationAdminCenterEventResponse> {
    let language = this.cookieService.get('skLanguageCode');
    if (!language) {
      language = 'en';
    }
    const partnerIdParam = `partnerId=${this.userProvider.getCurrentUser().partnerId}`;
    const languageParam = `language=${language}`;

    return this.http
        .get<ODataNotificationAdminCenterEventResponse>(`${this.getNotificationsODataRoute()}/odata/EventsAdminCenter?${partnerIdParam}&${languageParam}&${searchString}`, {
            headers: {
                [NgHttpCachingHeaders.TAG]: `eventAdminCenterGet-${productId}`,
            },
        })
        .pipe(catchError(this.handleError));
  }

  syncEventPreferences(
    payload: NotificationAdminCenterEventSettingsRequest,
    productId: number): Observable<unknown> {
      this.ngHttpCachingService.clearCacheByTag(`eventAdminCenterGet-${productId}`);
      return this.http
          .post(`${this.getNotificationsApiRoute()}/api/v1/EventsAdminCenter`, payload)
          .pipe(catchError(this.handleError));
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  getFlattenedPermissionName,
  NavigationArea,
  NavigationGuard,
  NavigationGuardData,
  NavigationPermission
} from '@skykick/core';
import { DeveloperComponent } from '../developer/developer.component';
import { DnsManagerComponent } from '../dns-manager/dns-manager.component';
import { HomeComponent } from '../home/home.component';
import { SETTINGS_ROUTES } from '../settings/settings-routing.module';
import { DnsManagerGuard } from '../utilities/dns-manager.guard';
import { PortalComponent } from './portal.component';

export const PORTAL_ROUTES: Routes = [
  {
    path: '',
    component: PortalComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            mandatoryPermissions: [
              getFlattenedPermissionName(
                NavigationArea.Home,
                NavigationPermission.Read
              )
            ],
            isPrimaryNavigation: true
          } as NavigationGuardData,
          SkNavigationArea: NavigationArea.Home,
        },
      },
      {
        path: 'partner/dns-manager',
        component: DnsManagerComponent,
        canActivate: [DnsManagerGuard],
        data: {
          SkNavigationArea: NavigationArea.DNS,
        },
      },
      {
        path: 'partner/developer',
        component: DeveloperComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            mandatoryPermissions: [
              getFlattenedPermissionName(
                NavigationArea.Developer,
                NavigationPermission.Read
              )
            ],
            isPrimaryNavigation: true
          } as NavigationGuardData,
          SkNavigationArea: NavigationArea.Developer,
        },
      },
      ...SETTINGS_ROUTES,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(PORTAL_ROUTES)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}

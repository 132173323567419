<div class="sk-flex-table">
  <div class="sk-scroll-container pt-200">
    <div class="pt-100" *ngIf="loadingAccountingPreferences || loadingBillingPreferences">
      <sk-text-spinner
        [message]="'sk-notifications-admin-center.preferences.general.loader_text' | translate"
      ></sk-text-spinner>
    </div>
    <div *ngIf="!loadingAccountingPreferences && !loadingBillingPreferences">
      <h3 class="pb-100">{{ 'sk-notifications-admin-center.title.general.text' | translate }}</h3>
      <span>{{ 'sk-notifications-admin-center.title.general.note' | translate }}</span>
      <div class="pt-100 col-md-5">
        <div class="pb-50">
          <sk-email-recipients-form
            *ngIf="!loadingAccountingPreferences && !accountPreferencesFailedToLoad"
            [helpText]="'sk-notifications-admin-center.preferences.account.help_text' | translate"
            [label]="'sk-notifications-admin-center.preferences.account.label' | translate"
            [emailRecipients]="newAccountPreferences"
            [savingPreferences]="savingPreferences"
            [isPrimary]="true"
            [optional]="true"
            [allowMultipleRecipients]="false"
            [preferencesCancelled]="preferencesCancelled.asObservable()"
            (formValue)="updateAccountPreferences($event)">
          </sk-email-recipients-form>
          <sk-feedback
            *ngIf="accountPreferencesFailedToLoad"
            [dismissible]="false"
            [subject]="'sk-notifications-admin-center.preferences.account.fetch_error_title' | translate"
            [message]="'sk-notifications-admin-center.preferences.account.fetch_error_message' | translate"
            type="danger"
          >
          </sk-feedback>
        </div>
        <div class="pb-50">
          <sk-email-recipients-form
            *ngIf="!loadingBillingPreferences && !billingPreferencesFailedToLoad"
            [helpText]="'sk-notifications-admin-center.preferences.billing.help_text' | translate"
            [label]="'sk-notifications-admin-center.preferences.billing.label' | translate"
            [emailRecipients]="newBillingPreferences"
            [savingPreferences]="savingPreferences"
            [isPrimary]="true"
            [optional]="true"
            [allowMultipleRecipients]="false"
            [preferencesCancelled]="preferencesCancelled.asObservable()"
            (formValue)="updateBillingPreferences($event)">
          </sk-email-recipients-form>
          <sk-feedback
            *ngIf="billingPreferencesFailedToLoad"
            [dismissible]="false"
            [subject]="'sk-notifications-admin-center.preferences.billing.fetch_error_title' | translate"
            [message]="'sk-notifications-admin-center.preferences.billing.fetch_error_message' | translate"
            type="danger"
          >
          </sk-feedback>
        </div>
      </div>
    </div>
  </div>
  <div class="sk-page-footer">
    <div class="sk-footer-content">
      <button sk-spinner-button class="btn-lg mr-100" [color]="'primary'" (click)="save()"
        [disabled]="isButtonDisabled()"
        [isSpinning]="savingPreferences">
        {{ 'sk-notifications-admin-center.save_button_text' | translate }}
      </button>
       <button (click)="cancel()" [disabled]="isButtonDisabled()" type="button" class="btn btn-lg btn-outline-secondary">
        {{ 'sk-notifications-admin-center.cancel_button_text' | translate }}
      </button>
    </div>
  </div>
</div>
